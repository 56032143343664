import { Component, OnInit } from '@angular/core';


declare var contactPageOnInit:any;
declare var carouselsContact:any;


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styles: [
  ]
})


export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    new contactPageOnInit('in');
    new carouselsContact();
  }

}
