
<div class="app-container">
    <div class="site voicesfy">
        <div class="pagecontainer">
            <div class="page page-fullwidth">
                <div class="wrapper">
    
                    <div class="wrapper relative iframecontainer">
                        <div class="overlay"></div>
                        <div class="imageplaceholder"></div>
                        <div id="main-iframe-container"><iframe id="iframe" src="https://player.vimeo.com/video/760807327?h=88464ee24e&amp;badge=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=1&amp;autoplay=0&amp;loop=0&amp;muted=1&amp;controls=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="width:100%;height:90%;" title="" data-ready="true"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                      </div>
    
                </div>
            </div>
        </div>
    </div>
    
    <div class="onpage maincontent">
        <div class="wrapper maintext">
            <div class="row">
                <div class="col">
                    <h1 class="maintitle white">voicesfy</h1>
                    <p class="mainp white">
                        When AI talks almost all the time, it will be more necessary than ever to access the best human talent in the world.
                    </p>
                </div>
            </div>    
            <div class="row group-btns01">
                <div class="col-md-auto buton buton01">
                    <a href="https://voicesfy.com" target="_blank">
                        <div class="btn btn00 info">
                        <div class="linksplitscrolla item white opacity0" ><a class="lateanimate"><div class="splita">MORE INFO </div></a></div>
                    </div>
                  </a>
                </div>
                <div class="col-md-auto">
                    <div id="circleProgress" class="circleP">
                        <div class="circlecontainer relative">
                            <div class="wrapper wrapper01">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
                                    <circle cx="17" cy="17" r="15.5" class="countdown__background"/>
                                    <circle cx="17" cy="17" r="15.5" class="countdown__load"/>
                                    <circle cx="17" cy="17" r="15.5" class="countdown__progress js-countdown__progress"/>
                                </svg>
                            </div>
                        </div>
                        <div class="textP">
                            <div class="wrapper">
                                <div class="txt text01">Loading...</div>
                                <div class="txt text02">Close video</div>    
                            </div>
                        </div>    
    
                      </div>      
        
                </div>
            </div>
        </div>
    </div>


    <div id="modal" class="modal01"></div>

    
    
</div>
