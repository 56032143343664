import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-excellence',
  templateUrl: './excellence.component.html',
  styles: [
  ]
})
export class ExcellenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
