import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PostproductionComponent } from './postproduction/postproduction.component';
import { FilmingComponent } from './filming/filming.component';
import { ExcellenceComponent } from './excellence/excellence.component';
import { ContactComponent } from './contact/contact.component';
import { VoicesfyComponent } from './voicesfy/voicesfy.component';
import { CookiesComponent } from './cookies/cookies.component';
import { PrivacyComponent } from './privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    PostproductionComponent,
    FilmingComponent,
    ExcellenceComponent,
    ContactComponent,
    VoicesfyComponent,
    CookiesComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
